import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { createTrackedSelector } from 'react-tracked'
import { GetSignInInfoResp } from '@/typings/api/marketing/sign-in'

const getStore = set => ({
  /** 当前是否已签到 */
  hasSignIn: false,
  /** 是否需要展示引导 */
  shouldShowIntro: false,
  /** 是否需要展示提醒 放到Store 防止当前会话内重复提醒 */
  shouldRemind: false,
  /** 活动信息 */
  activiy: null as unknown as GetSignInInfoResp,
  /** 是否正在请求 */
  loading: false,
  setHasSignIn: hasSignIn => set({ hasSignIn }),
  setShouldShowIntro: shouldShowIntro => set({ shouldShowIntro }),
  setShouldRemind: shouldRemind => set({ shouldRemind }),
  setActivity: activiy => set({ activiy }),
  setLoading: loading => set({ loading }),
  /** 显示规则提示 */
  showTips: false,
  setShowTips: showTips => set({ showTips }),
  /** 显示签到成功提示 */
  showSignTips: false,
  setShowSignTips: showSignTips => set({ showSignTips }),
  /** 显示取消提示弹框 */
  showSignTip: false,
  setShowSignTip: showSignTip => set({ showSignTip }),
  resetStore: () =>
    set({ hasSignIn: false, shouldShowIntro: false, shouldRemind: false, activiy: null, loading: false }),
})

const baseSignInActivityStore = create(devtools(getStore, { name: 'sign-in-activity-store' }))
const useSignInActivityStore = createTrackedSelector(baseSignInActivityStore)

export { useSignInActivityStore, baseSignInActivityStore }
